import { ParamMap } from '@angular/router';
import { SUPPORTED_LANGUAGES } from '../constants';
import {
  EntranceOptions,
  Event,
  ListInvitee,
  Location,
  LoungeType,
} from '../models/app.models';

export function getEntranceOptions(
  queryParamMap: ParamMap,
): EntranceOptions | null {
  const langQueryParam = queryParamMap.get('lang');
  const sessionKey = queryParamMap.get('sessionKey');

  return <EntranceOptions>{
    lang:
      langQueryParam && SUPPORTED_LANGUAGES.includes(langQueryParam)
        ? langQueryParam
        : 'de',
    sessionKey: sessionKey || undefined,
  };
}

export function formatTime(time: string, dividerChar: string): string {
  if (!isLastCharDoubleDot(time) && time.length === 2) {
    return time + dividerChar;
  } else {
    return checkOnDeletedDoubleDot(time, dividerChar);
  }
}

export function checkOnDeletedDoubleDot(
  time: string,
  dividerChar: string,
): string {
  if (!isLastCharDoubleDot(time) && time.length === 3) {
    return addDeletedDoubleDot(time, dividerChar);
  }
  return time;
}

export function formatRoute(route: string): string {
  const splitRoute = route.split(`/`);
  return splitRoute[splitRoute.length - 1];
}

export function isLastCharDoubleDot(time: string): boolean {
  return time.charAt(time.length - 1) === ':';
}

export function addDeletedDoubleDot(date: string, dividerChar: string): string {
  return getValueBeforeInput(date) + dividerChar + getNewestInput(date);
}

export function formatDate(date: string, dividerChar: string): string {
  if (!isLastCharNotDot(date) && (date.length === 2 || date.length === 5)) {
    return date + dividerChar;
  } else {
    return checkOnDeletedDot(date, dividerChar);
  }
}

export function compareSearchResults(
  a: string,
  b: string,
  searchString: string,
): number {
  const aHasSearchStringAtIndex = a.indexOf(searchString);
  const bHasSearchStringAtIndex = b.indexOf(searchString);

  if (aHasSearchStringAtIndex === 0 && bHasSearchStringAtIndex === 0) {
    return a.localeCompare(b);
  }
  if (aHasSearchStringAtIndex === 0 && bHasSearchStringAtIndex !== 0) {
    return -1;
  }

  if (aHasSearchStringAtIndex !== 0 && bHasSearchStringAtIndex === 0) {
    return 1;
  }
  return a.localeCompare(b);
}

export function checkOnDeletedDot(date: string, dividerChar: string): string {
  if (isLastCharNotDot(date) && (date.length === 3 || date.length === 6)) {
    return addDeletedDot(date, dividerChar);
  }
  return date;
}

export function isLastCharNotDot(date: string): boolean {
  return date.charAt(date.length - 1) !== '.';
}

export function addDeletedDot(date: string, dividerChar: string): string {
  return getValueBeforeInput(date) + dividerChar + getNewestInput(date);
}

export function getValueBeforeInput(date: string): string {
  return date.slice(0, date.length - 1);
}

export function getNewestInput(date: string): string {
  return date.slice(date.length - 1);
}

export function getLocationById(
  id: number,
  locations: Location[],
): Location | undefined {
  return locations.find((location) => location.id === id);
}

export function getLoungeTypeById(
  id: number,
  loungeTypes: LoungeType[],
): LoungeType | undefined {
  return loungeTypes.find((loungeType) => loungeType.id === id);
}

export function makeDateValid(date: string) {
  const eventDate = new Date(date);
  return new Date(
    eventDate.getTime() + Math.abs(eventDate.getTimezoneOffset() * 60000),
  ).toISOString();
}

export function formatPrice(price: number) {
  return price.toFixed(2);
}

export function getEventId(event: Event) {
  if (event.eventStatus === 'online' || event.eventStatus === 'archived') {
    return event.id;
  }

  if (event.eventStatus === 'pending' && event.eventId) {
    return event.eventId;
  }

  return undefined;
}

export function toggleValueInArray(array: any[], value: any) {
  if (array.includes(value)) {
    return array.filter((item) => item !== value);
  }

  return [...array, value];
}

export function toggleValueInArrayWithLimit(
  array: any[],
  value: any,
  limit: number,
) {
  if (array.length === limit && !array.includes(value)) {
    return array;
  }

  return toggleValueInArray(array, value);
}

export function setReadOnlyForInvitee(
  listInvitees: ListInvitee[],
  listInvitee: ListInvitee,
) {
  return listInvitees.map((invitee) => {
    if (listInvitee.id === invitee.id) {
      return {
        ...invitee,
        isReadOnly: !invitee.isReadOnly,
      };
    }
    return invitee;
  });
}

export function setCheckInOutInvitee(
  listInvitees: ListInvitee[],
  listInvitee: ListInvitee,
) {
  return listInvitees.map((invitee) => {
    if (listInvitee.id === invitee.id) {
      return {
        ...invitee,
        isCheckedIn: !invitee.isCheckedIn,
      };
    }
    return invitee;
  });
}

export function addInviteeToList(
  listInvitees: ListInvitee[],
  listInvitee: ListInvitee,
) {
  listInvitees = [...listInvitees, listInvitee];
  return listInvitees;
}

export function removeInviteeFromList(
  listInvitees: ListInvitee[],
  listInvitee: ListInvitee,
) {
  return listInvitees.filter((item) => item.id !== listInvitee.id);
}

export function getCookieName(env: string): string {
  switch (env) {
    case 'local':
      return 'data-izzo';
    case 'dev':
      return 'data-izzo-dev';
    case 'vpr':
      return 'data-izzo-vpr';
    case 'production':
      return 'data-izzo';
    default:
      throw new Error('Unknown environment');
  }
}

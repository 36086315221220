export const SUPPORTED_LANGUAGES: string[] = ['de', 'en'];
export const regexPatterns = {
  mailRegexApi:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\x7f-\xff\-0-9]+\.)+[a-zA-Z\x7f-\xff]{2,}))$/i,
  tel: /^[0-9 .,\-+()/]{7,20}$/,
  plz: /^([1-468][0-9]|[57][0-7]|9[0-6])[0-9]{2}$/,
  password:
    /^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[$@$!%?&])[A-Za-z\d$@$!%?&].{8,}$/,
  time: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
};

export const JWT_KEY = 'izzo-admin-2fa-jwt';

export const TIME_FORMAT = 'HH:mm';
